import { Theme } from "@mychili/ui-web";
import { FormControl } from "../form-control";
import { InputLabel } from "../input-label";

type StylesWrapperProps = {
  children: React.ReactNode;
  label?: string;
};

const sx = {
  ".rmdp-wrapper": {
    borderRadius: "10px",
  },
  ".rmdp-header": {
    padding: 0,
    margin: 0,
  },
  ".rmdp-calendar": {
    padding: "16px",
    paddingTop: "8px",
  },
  ".rmdp-day-picker": {
    padding: 0,
  },
  ".rmdp-day": {
    width: "36px",
    height: "32px",
  },
  ".rmdp-day span": {
    borderRadius: "10px",
    backgroundColor: "inherit",
  },
  ".rmdp-shadow": {
    boxShadow:
      "0px 1.63px 3.259px 0px rgba(0, 0, 0, 0.06), 0px 7.704px 12.741px 0px rgba(0, 0, 0, 0.06), 0px 12px 30px 0px rgba(0, 0, 0, 0.10)",
  },
  // Range
  ".rmdp-range": (theme: Theme) => ({
    backgroundColor: theme.palette.neutral["90"],
    boxShadow: "none",
    color: theme.palette.neutral["20"],
  }),
  ".rmdp-range-hover": (theme: Theme) => ({
    backgroundColor: theme.palette.neutral["90"],
    boxShadow: "none",
    color: theme.palette.neutral["20"],
  }),
  ".rmdp-range-hover:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  ".rmdp-range-hover:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  ".rmdp-range-hover.start": (theme: Theme) => ({
    backgroundColor: theme.palette.common.primary,
    boxShadow: "none",
    color: theme.palette.common.white,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  }),
  ".rmdp-range-hover.end": (theme: Theme) => ({
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
  }),
  ".rmdp-range.start:not(.force)": (theme: Theme) => ({
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
  }),
  ".rmdp-range.start:not(.force) span:hover": (theme: Theme) => ({
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
  }),
  ".rmdp-range.end:not(.force)": (theme: Theme) => ({
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
  }),
  ".rmdp-range.end:not(.force) span:hover": (theme: Theme) => ({
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
  }),
  ".rmdp-range.end:not(.force):hover": (theme: Theme) => ({
    backgroundColor: theme.palette.common.primary,
    color: theme.palette.common.white,
  }),
  ".rmdp-range:first-child": {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  ".rmdp-range:last-child": {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  ".rmdp-range:first-child:last-child": {
    backgroundColor: "green",
  },
  // Week
  ".rmdp-week": {
    marginBottom: "4px",
  },
  ".rmdp-week-day": (theme: Theme) => ({
    color: theme.palette.neutral["20"],
  }),
  ".rmdp-week .rmpd-range:first-of-type": {
    backgroundColor: "red",
  },
  // Header
  ".rmdp-header-values > div": {
    justifyContent: "space-between",
  },
  ".rmdp-header-values": {
    flex: 1,
  },
  ".rmdp-header-values:first-of-type": {
    paddingRight: "40px",
  },
  ".rmdp-header-values:last-of-type": {
    paddingLeft: "40px",
  },
  ".rmdp-header-values span": (theme: Theme) => ({
    padding: 0,
    fontSize: "1rem",
    fontWeight: 700,
    color: theme.palette.neutral["20"],
  }),
  ".rmdp-header-values span:last-child": (theme: Theme) => ({
    color: theme.palette.common.primary,
  }),
  ".rmdp-day.rmdp-selected": (theme: Theme) => ({
    backgroundColor: theme.palette.common.primary,
    borderRadius: "10px",
  }),
  ".rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: "inherit",
    boxShadow: "none",
  },
  ".rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden,.rmdp-selected,.rmdp-range,.rmdp-range-hover):hover":
    (theme: Theme) => ({
      backgroundColor: theme.palette.neutral["90"],
      color: theme.palette.neutral["20"],
      borderRadius: "10px",
    }),
  ".rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden,.rmdp-selected) span:hover": {
    backgroundColor: "inherit",
    color: "inherit",
  },
  // Weekend
  ".rmdp-day span.weekend": (theme: Theme) => ({
    color: theme.palette.common.primary,
  }),
  ".rmdp-range.end:not(.force) span.weekend": (theme: Theme) => ({
    color: theme.palette.common.white,
  }),
  ".rmdp-range-hover.start span.weekend": (theme: Theme) => ({
    color: theme.palette.common.white,
  }),
  ".rmdp-range-hover.end span.weekend": (theme: Theme) => ({
    color: theme.palette.common.white,
  }),
  ".rmdp-day.rmdp-selected span.weekend": (theme: Theme) => ({
    color: theme.palette.common.white,
  }),
};

export const Wrapper = ({ children, label }: StylesWrapperProps) => {
  return (
    <FormControl sx={sx}>
      <InputLabel>{label}</InputLabel>
      {children}
    </FormControl>
  );
};
