import { GetOrdersItem } from "shared/api";
import { LabelProps } from "shared/ui";

export const getStatusMappingData = (
  status: GetOrdersItem["status"],
): { title: string; color: LabelProps["color"] } => {
  let color: LabelProps["color"] = "neutral";

  switch (status) {
    case "PENDING":
    case "PROCESSING":
    case "CONFIRMED":
    case "REFUND_IN_PROGRESS":
      color = "info";
      break;
    case "ACTIVE":
    case "REPAID":
      color = "success";
      break;
    case "REFUNDED":
      color = "warning";
      break;
    case "EXPIRED":
    case "REJECTED":
    case "CANCELLED":
    case "REFUND_FAILED":
    case "PAYMENT_FAILED":
      color = "error";
      break;
  }

  return {
    title: status,
    color,
  };
};
