import { IconChevronDown, IconClose } from "@mychili/ui-web";
import { Value } from "react-multi-date-picker";

import { IconButton } from "../icon-button";
import { Input, InputProps } from "../input";

type RangeInputProps = {
  openCalendar: () => void;
  onFocus?: () => void;
  value?: Value | undefined;
  separator?: string;
  placeholder?: InputProps["placeholder"];
  color?: InputProps["color"];
  onClear?: () => void;
};

const ClearButton = ({ onClick }: { onClick: () => void | undefined }) => (
  <IconButton onClick={onClick}>
    <IconClose fontSize="small" />
  </IconButton>
);

export const TextInput = ({
  onFocus,
  value,
  separator,
  placeholder,
  onClear,
  color = "white",
  openCalendar,
}: RangeInputProps) => {
  let displayValue = "";

  if (typeof value === "string" && separator) {
    const values = value.split(separator);
    const from = values[0] || "";
    const to = values[1] || "";

    displayValue = from && to ? `${from} - ${to}` : from;
  } else if (value) {
    displayValue = value as string;
  }

  return (
    <Input
      autoComplete="off"
      color={color}
      onFocus={onFocus}
      placeholder={placeholder}
      value={displayValue}
      sx={{
        ".MuiInputBase-adornedEnd": {
          pl: 0,
          pr: 2,
        },
      }}
      fullWidth
      InputProps={{
        sx: {
          // @TODO Remove styles after adding small inputs to DS
          ".MuiInputBase-input": {
            cursor: "pointer",
            px: 0,
            py: 1.5,
            fontSize: 14,
            lineHeight: 21,
          },
        },
        endAdornment:
          onClear && displayValue ? (
            <ClearButton onClick={onClear} />
          ) : (
            // @todo temp fix, will be refactored in new date-picker version
            <IconChevronDown
              onClick={openCalendar}
              sx={{ cursor: "pointer" }}
            />
          ),
      }}
    />
  );
};
