import "react-image-crop/dist/ReactCrop.css";

import { useBoolean } from "@mychili/ui-web";
import { useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { config, showSnackbarError } from "shared/lib";
import {
  BaseTooltip,
  Button,
  Dialog,
  IconButton,
  IconDelete,
  IconUpload,
  Logo,
  Stack,
  Typography,
} from "shared/ui";

type LogoUploadProps = {
  onComplete: (blob: Blob | undefined) => void;
  initialLogoSrc?: string;
};

const DEFAULT_CROP_STATE: Crop = {
  unit: "px",
  x: 25,
  y: 25,
  width: 200,
  height: 200,
};

export const LogoUpload = ({
  onComplete,
  initialLogoSrc = "",
}: LogoUploadProps) => {
  const cropDialog = useBoolean();

  const [crop, setCrop] = useState<Crop>(DEFAULT_CROP_STATE);

  const [logoSrc, setLogoSrc] = useState("");
  const [croppedLogoSrc, setCroppedLogoSrc] = useState(initialLogoSrc);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const cropRef = useRef<ReactCrop>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const handleUploadLogoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = new Image();
      image.src = URL.createObjectURL(e.target.files[0]);

      await image.decode();
      URL.revokeObjectURL(image.src);

      if (image.width < config.project.minLogoWidth) {
        showSnackbarError("Image width cannot be less than 512px");
        return;
      }

      setCrop(DEFAULT_CROP_STATE);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setLogoSrc(reader.result?.toString() || ""),
      );
      reader.readAsDataURL(e.target.files[0]);

      cropDialog.on();
    }
  };

  const handleClear = () => {
    setCroppedLogoSrc("");
    setLogoSrc("");
    onComplete(undefined);
  };

  const handleClose = () => {
    cropDialog.off();
    handleClear();
  };

  const handleSaveCrop = async () => {
    if (imageRef.current && cropRef.current?.props.crop) {
      setLogoSrc("");
      const cropState = cropRef.current?.props.crop;
      const image = imageRef.current;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const canvas = document.createElement("canvas");
      canvas.width = cropState.width;
      canvas.height = cropState.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctx.drawImage(
        image,
        cropState.x * scaleX,
        cropState.y * scaleY,
        cropState.width * scaleX,
        cropState.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      cropDialog.off();

      const dataURL = canvas.toDataURL("image/png");
      setCroppedLogoSrc(dataURL);

      canvas.toBlob((blob) => {
        if (blob) onComplete(blob);

        canvas.remove();
      });
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Logo src={croppedLogoSrc} />
        <input
          id="logo-upload"
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          accept="image/*"
        />

        <Stack direction="row" spacing={1}>
          {croppedLogoSrc ? (
            <BaseTooltip title="Delete" placement="top">
              <div>
                <IconButton size="large" color="gray" onClick={handleClear}>
                  <IconDelete />
                </IconButton>
              </div>
            </BaseTooltip>
          ) : (
            <BaseTooltip title="Upload" placement="top">
              <div>
                <IconButton
                  size="large"
                  color="gray"
                  onClick={handleUploadLogoClick}
                >
                  <IconUpload />
                </IconButton>
              </div>
            </BaseTooltip>
          )}
        </Stack>
      </Stack>

      <Typography mt={1.5} color="neural.70" variant="extraSmallTextRegular">
        WebP, SVG, PNG, or JPEG accepted. Minimum 512 pixels.
      </Typography>

      <Dialog
        open={cropDialog.value}
        title="Crop image"
        onClose={cropDialog.off}
        fullWidth
        sx={{
          ".MuiDialog-container .MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            maxWidth: "100%",
          },
        }}
      >
        <Stack sx={{ background: "transparent" }} justifyContent="center">
          <Stack
            alignItems="center"
            sx={{
              "&.MuiStack-root .ReactCrop__crop-selection": {
                animation: "none",
                background: "none",
                border: "1px solid #fff",
                outline: "none",
              },
              "&.MuiStack-root .ReactCrop__drag-handle": {
                borderRadius: "50%",
              },
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              ref={cropRef}
              aspect={1}
              minWidth={80}
              maxWidth={600}
              circularCrop
            >
              <img src={logoSrc} ref={imageRef} />
            </ReactCrop>
          </Stack>

          <Stack direction="row" justifyContent="center" spacing={1} pt={2.5}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" onClick={handleSaveCrop}>
              Save photo
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
