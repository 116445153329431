import { IconChevronLeft, IconChevronRight } from "@mychili/ui-web";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import ReactMultiDatePicker, {
  CalendarProps,
  DateObject,
  DatePickerProps as ReactMultiDatePickerProps,
  Value,
} from "react-multi-date-picker";

import { IconButton } from "../icon-button";
import { InputProps } from "../input";
import { TextInput } from "./text-input";
import { Wrapper } from "./wrapper";

export type DatePickerProps = Omit<CalendarProps, "onChange"> &
  Omit<ReactMultiDatePickerProps, "onChange"> & {
    onChange: (value: Date | Date[] | undefined) => void;
    inputColor?: InputProps["color"];
    label?: string;
  };

const renderMonthNavigationButtons = (
  direction: "left" | "right",
  handleClick: () => void,
) => (
  <IconButton onClick={handleClick}>
    {direction === "right" ? <IconChevronRight /> : <IconChevronLeft />}
  </IconButton>
);

type MapDaysParams = {
  date: DateObject;
  selectedDate: DateObject | DateObject[];
  currentMonth: object;
  isSameDate(arg1: DateObject, arg2: DateObject): boolean;
  today: DateObject;
};

type DatePickerRef = {
  closeCalendar: () => void;
};

export const DatePicker = (props: DatePickerProps) => {
  const {
    format = "DD.MM.YYYY",
    value,
    onChange,
    range,
    placeholder,
    label,
    inputColor,
  } = props;

  const [innerValue, setInnerValue] = useState<Value | undefined>(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const datePickerRef = useRef<DatePickerRef>();

  const handleCloseCalendar = () => {
    if (datePickerRef.current) {
      datePickerRef.current.closeCalendar();
    }
  };

  const mapDays = ({ date, today, isSameDate }: MapDaysParams) => {
    const mapDaysProps: HTMLAttributes<HTMLSpanElement> = {
      style: {
        fontSize: 16,
      },
    };

    const isWeekend = [0, 6].includes(date.weekDay.index);

    if (isWeekend) {
      mapDaysProps.className = "weekend";
    }

    if (isSameDate(date, today)) {
      mapDaysProps.style!.textDecoration = "underline";
    }

    return mapDaysProps;
  };

  const handleChange = (val: DateObject | DateObject[] | null) => {
    setInnerValue(val);

    if (range) {
      if (Array.isArray(val) && val.length === 2) {
        const dateFrom = val[0].toDate();
        const dateTo = val[1].toDate();

        onChange([dateFrom, dateTo]);
        handleCloseCalendar();
      }
    } else {
      onChange(val instanceof DateObject ? val.toDate() : undefined);
      handleCloseCalendar();
    }
  };

  const handleClear = () => {
    onChange(undefined);
    setInnerValue(undefined);
  };

  const handleClose = () => {
    if (Array.isArray(innerValue) && innerValue.length < 2) {
      setInnerValue(value);
    }
  };

  return (
    <Wrapper label={label}>
      {/* @ts-ignore fix error after react-multi-date-picker update */}
      <ReactMultiDatePicker
        {...props}
        arrow={false}
        onChange={handleChange}
        value={innerValue}
        format={format}
        mapDays={mapDays}
        renderButton={renderMonthNavigationButtons}
        monthYearSeparator=" "
        rangeHover
        highlightToday={false}
        onClose={handleClose}
        ref={datePickerRef}
        range={range}
        render={
          // @ts-ignore fix after date-picker component update
          <TextInput
            onClear={innerValue ? handleClear : undefined}
            color={inputColor}
            placeholder={placeholder}
            value={innerValue}
          />
        }
      />
    </Wrapper>
  );
};
