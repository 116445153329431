import { ColumnDef } from "@tanstack/react-table";
import { GetOrdersItem } from "shared/api";
import { formatDate } from "shared/lib";
import { Label, SortingOptions } from "shared/ui";

import { getStatusMappingData } from "./get-status-mapping-data";

export const COLUMNS: ColumnDef<GetOrdersItem, any>[] = [
  {
    accessorKey: "created",
    header: "Date",
    cell: (cell) => {
      const value = cell.getValue();
      return value instanceof Date ? formatDate(value, "DD.MM.YYYY") : "";
    },
  },
  {
    accessorKey: "orderNumber",
    header: "ID",
  },
  {
    accessorKey: "status",
    header: "State",
    cell: (cell) => {
      const { title, color } = getStatusMappingData(cell.getValue());
      return <Label color={color}>{title}</Label>;
    },
  },
  {
    accessorKey: "tradeOutletName",
    header: "Store",
  },
  {
    accessorKey: "employeeName",
    header: "Employee",
  },
  {
    accessorKey: "amount",
    header: "Amount",
  },
];

export const SORTING_OPTIONS: SortingOptions<GetOrdersItem> = {
  created: [
    {
      name: "Newest first",
      id: "-created",
    },
    {
      name: "Oldest first",
      id: "created",
    },
  ],
  orderNumber: [
    {
      name: "Newest first",
      id: "-order_number",
    },
    {
      name: "Oldest first",
      id: "order_number",
    },
  ],
  status: [
    {
      name: "A-Z",
      id: "-status",
    },
    {
      name: "Z-A",
      id: "status",
    },
  ],
  tradeOutletName: [
    {
      name: "A-Z",
      id: "trade_outlet_name",
    },
    {
      name: "Z-A",
      id: "-trade_outlet_name",
    },
  ],
  employeeName: [
    {
      name: "A-Z",
      id: "employee_name",
    },
    {
      name: "Z-A",
      id: "-employee_name",
    },
  ],
  amount: [
    {
      name: "ASC",
      id: "amount",
    },
    {
      name: "DESC",
      id: "-amount",
    },
  ],
};
